/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* istanbul ignore file */
import * as Survey from "survey-react";
import { format } from "date-fns";
import {
    InteractionPayload,
    LinkPayload,
    MemberPayload,
    RegisterPayload,
} from "../config/register/payload";
import { addWhakapapa } from "../service/register/utilities";

function getGender(gender: string): string {
    if (gender === "Tāne") {
        return "Male";
    }
    if (gender === "Wahine") {
        return "Female";
    }
    return "";
}

export function transformRegistrationFormData(
    survey: Survey.SurveyModel,
    tupunaBaseId: string
): RegisterPayload {
    const surveyData = survey.data;
    const gender = getGender(surveyData.gender);
    const dob = format(new Date(surveyData.dob), "yyyyMMdd");

    const data: MemberPayload = {
        title: surveyData.title,
        firstName: surveyData.firstName,
        middleNames: surveyData.middleNames,
        surname: surveyData.surname,
        gender,
        cell: surveyData.cell,
        phone: surveyData.phone,
        email: surveyData.email,
        occupation: surveyData.occupation,
        line1: surveyData.addressfinder.address_line_1 || "",
        line2: surveyData.addressfinder.address_line_2 || "",
        suburb: surveyData.addressfinder.suburb || "",
        city: surveyData.addressfinder.city || "",
        postcode: surveyData.addressfinder.postcode || "",
        country: surveyData.addressfinder.country || "",
        dob,
        tupunaBaseId,
    };
    let interactions: InteractionPayload[] = [];
    const links: LinkPayload[] = [];

    ["education", "details"].forEach((attribute) => {
        if (surveyData[attribute]) {
            const capitalisedAttribute =
                attribute[0].toUpperCase() + attribute.slice(1);
            const value = `${capitalisedAttribute}: ${surveyData[attribute]}`;

            interactions.push({
                value,
            });
        }
    });

    if (surveyData.signed) {
        links.push({
            type: "attachment",
            value: surveyData.signed,
        });
    }

    interactions = [
        ...interactions,
        ...addWhakapapa(
            surveyData.whakapapa.mother,
            surveyData.whakapapa.father,
            ""
        ),
    ];

    return { data, interactions, links, recaptcha: null, tenant: "KUIA" };
}
